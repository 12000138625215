<template>
  <div>
    <!-- popup подтверждающий удаление -->

    <DeletePopupCommon
      v-if="deleteCkeckboxList.length > 1"
      title="список сотрудников"
      description="ВНИМАНИЕ! После удаления сотрудников они не смогут получить доступ к вашему объекту по приложению. Оплата за активированные тарифы не возвращается.

Карты доступа также станут неактивной.
Вы уверены, что хотите удалить сотрудников?"
      :name="nameUser"
      :dialog="dialog"
      @closeDeletePopup="closeDeletePopup"
      :delete-function="deleteFunction"
    />
    <DeletePopupCommon
      v-else
      title="сотрудника"
      description="ВНИМАНИЕ! После удаления сотрудника он не сможет получить доступ к вашему объекту по приложению. Оплата за активированные тарифы не возвращается.

Карта доступа также станет неактивной.
Вы уверены, что хотите удалить сотрудника?"
      :name="nameUser"
      :dialog="dialog"
      @closeDeletePopup="closeDeletePopup"
      :delete-function="deleteFunction"
    />
    <!-- /popup подтверждающий удаление-->

    <TableFilters :queries="[]">
      <template v-slot:filter-title>
        <span v-if="sourceObject.count">
          <span class="title-filter">Всего:</span>

          <span v-if="sourceObjectMaxUsers || sourceObject.count">
            {{ sourceObject.count }} из
            {{ sourceObjectMaxUsers ? sourceObjectMaxUsers : "ꝏ" }}
          </span>
        </span>
      </template>

      <template v-slot:add-button>
        <v-btn
          :disabled="deleteCkeckboxList.length != 1"
          :to="'/personnelEdit/' + selectedUserId"
          append
          class="btn btn-edit btn_color_grey"
          @click="clearCheckboxes"
          >редактировать
        </v-btn>
        <router-link
          to="PersonnelCreate"
          append
          class="btn btn-add btn_color_green mr-5"
        >
          {{
            !($store.getters.getCurrentRole === 60)
              ? "Добавить сотрудника"
              : "Добавить пользователя"
          }}
        </router-link>

        <changePersonnelRole
          name="Сотрудника"
          title="Сотрудника"
          :save-function="transferPersonnelRole"
        />
      </template>
      <template v-if="deleteCkeckboxList.length > 1" v-slot:delete-button>
        <v-btn
          :disabled="isDisabled"
          color="#EB5C6D"
          class="btn btn-delete btn_color_red"
          @click="clickDelete"
          style="color:white"
        >
          Удалить сотрудников
        </v-btn>
      </template>
      <template v-else v-slot:delete-button>
        <v-btn
          :disabled="isDisabled"
          color="#EB5C6D"
          class="btn btn-delete btn_color_red"
          style="color:white"
          @click="clickDelete"
        >
          Удалить сотрудника
        </v-btn>
      </template>
    </TableFilters>

    <TableLayout
      :loading="isDateLoaded"
      to="children"
      disable-router
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
      delete-all
      dropDown
    >
      <template slot-scope="{ itemFather }">
        <v-checkbox
          class="ma-0 pa-0 checkbox-type"
          :value="itemFather.id"
          v-model="deleteCkeckboxList"
          hide-details
        ></v-checkbox>
      </template>
    </TableLayout>
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import TableFilters from "@/components/tables/TableFilters";
import { getUsersClearRequest } from "@/helpers/api/user";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";
import DeletePopupCommon from "@/components/popup/DeletePopupCommon";
import changePersonnelRole from "@/components/popup/changePersonnelRole";

export default {
  name: "KindergartensPersonnel",
  components: {
    TableFilters,
    TableLayout,
    DeletePopupCommon,
    changePersonnelRole
  },
  data() {
    return {
      loadingBtn: false,
      dialog: false, // Диалоговое окно popup удаления
      telUser: "", // Телефон пользователя
      nameUser: "", // Имя пользователя
      objUser: {},

      header: [
        {
          text: "Пользователь",
          renderFunction: value => {
            return value?.additional_fields.length != 0
              ? value?.additional_fields[0].value
              : "-";
          }
        },
        {
          text: "Комментарий",
          renderFunction: value => {
            return value?.additional_fields.length != 0
              ? value?.additional_fields[1].value
              : "-";
          }
        },
        {
          text: "Телефон",
          renderFunction: value => phoneFilter.execute(value?.phone_number)
        },
        {
          text: "Роль",
          // value: "phone_number"
          renderFunction: value =>
            `<div style="color: ${
              value.position == 30 ? "#95C23D" : " #000"
            }">${
              value.position == 30
                ? "Администратор"
                : value.position == 20
                ? "Воспитатель"
                : "Другой персонал"
            }</div>`
        }

        // {
        //   text: "№ группы",
        //   renderFunction: value =>
        //     value?.children_groups
        //       ? value.children_groups.filter(elem => elem != "").join(", ")
        //       : "-"
        // }
      ]
    };
  },
  methods: {
    transferPersonnelRole() {
      this.$store.commit("setRouterQuery", {
        routes: this.$route.params
      });

      this.$store
        .dispatch("changeUserToGroup", {
          data: {
            id: this.$store.getters.getUsers,
            group: localStorage.getItem("newRole")
          }
        })
        .then(() => {
          localStorage.removeItem("oldRole");
          this.$store.dispatch(
            "successNotification",
            actionMessage.change(successMessageDictionary.role)
          );
        })
        .then(() => {
          this.$store.dispatch("fetchPersonnelList");
        });
    },
    async deleteFunction() {
      const userId =
        (
          await getUsersClearRequest({
            query: {
              phone_number: this.telUser
            }
          })
        ).data.results[0].id || null;

      let self = this;

      this.isDateLoaded = true;
      this.$store
        .dispatch("deletePersonnelFromGroup", {
          id: userId,
          // personnelGroupId: this.personnelGroupId,
          kindergartenId: this.$store.state.searchQuery.pageQuery.id,
          role: self.objUser.position
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.personnel)
          );
        })
        .finally(() => {
          this.$store.dispatch("fetchPersonnelList").finally(() => {});
        });

      let data = {
        ids: this.deleteCkeckboxList,
        kindergartens_pk: this.$store.state.searchQuery.pageQuery.id
      };
      this.$store
        .dispatch("deleteArrPersonal", { data })
        .then(() => {
          this.isDateLoaded = true;
          this.$store.dispatch("fetchPersonnelList").finally(() => {
            this.isDateLoaded = false;
          });
        })
        .then(() => {
          this.$store.dispatch("clearCheckedList");
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.personnel)
          );
        });
    },
    async deleteUser() {
      const userId =
        (
          await getUsersClearRequest({
            query: {
              phone_number: this.telUser
            }
          })
        ).data.results[0].id || null;

      let self = this;

      this.isDateLoaded = true;
      this.$store
        .dispatch("deletePersonnelFromGroup", {
          id: userId,
          // personnelGroupId: this.personnelGroupId,
          kindergartenId: this.$store.state.searchQuery.pageQuery.id,
          role: self.objUser.position
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.personnel)
          );
        })
        .finally(() => {
          this.$store.dispatch("fetchPersonnelList").finally(() => {
            this.isDateLoaded = false;
          });
        });
    },
    clearCheckboxes() {
      this.$store.commit("UPDATE_DELETE_LIST", []);
    },

    clickDelete(obj) {
      this.objUser = obj;
      this.nameUser = obj.full_name;
      this.telUser = obj.phone_number;
      this.dialog = true;
    },

    closeDeletePopup(data) {
      this.dialog = data;
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: this.urlQuery.query
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchOrganizationUsersList");
    this.$store
      .dispatch("fetchPersonnelList", { limit: 1000 })
      .then(() => {
        this.$store.dispatch("fetchPersonnelGroup", {
          organization: this.$store.state.searchQuery.pageQuery.id,
          role: 80
        });
      })
      .finally(() => {
        this.isDateLoaded = false;
      });
  },
  computed: {
    selectedUserId() {
      return this.deleteCkeckboxList.length === 1
        ? this.deleteCkeckboxList[0]
        : null;
    },
    isDisabled() {
      return this.deleteCkeckboxList.length <= 0 ? true : false;
    },
    deleteCkeckboxList: {
      get() {
        return this.$store.getters.getDeleteList;
      },
      set(value) {
        this.$store.commit("UPDATE_DELETE_LIST", value);
        if (this.$store.getters.getCheckedAll.includes("all")) {
          this.$store.commit("SET_CHECKED_ALL", []);
        }
      }
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getPersonnelList;
    },
    sourceObjectUsersList() {
      return this.$store.getters.getOrganizationUsersList;
    },
    sourceObjectMaxUsers() {
      return this.$store.getters.getDealerInfo.results?.free_participant_limit;
    },
    personnelGroupId() {
      return this.$store.getters.getKindergartenPersonnelGroupID;
    }
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchPageKindergartensList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("setSearchQuery", {
      query: {}
    });
    next();
  }
};
</script>

<style scoped></style>
