<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries" :loading="isDateLoaded">
      <template v-slot:filter-title>
        <span v-if="sourceObject.count">
          <span class="title-filter">Всего:</span>{{ sourceObject.count }}
          <!--          <span>детей</span>-->
        </span>
      </template>
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link
          to="createChildren"
          append
          class="btn btn-add btn_color_green"
        >
          Добавить воспитанника
        </router-link>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to="children"
      :table-data="sourceObject.results"
      :source-object="sourceObject"
      :header="header"
    />
  </div>
</template>

<script>
import TableFilters from "@/components/tables/TableFilters";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import phoneFilter from "@/helpers/filters/phoneFilter";
export default {
  name: "KindergartensChildren",
  components: { TableLayout, TableFilters },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        kindergartens_pk: this.urlQuery.id,
        children_group__group__organization: this.urlQuery.id
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchChildrenByKindergartens").finally(() => {
      this.isDateLoaded = false;
    });
  },
  data() {
    return {
      loadingBtn: false,
      queries: query.Children,
      header: [
        {
          text: "Воспитанник",
          value: "name",
          renderFunction: value => {
            return `<div>${value.name}<div>`;
          },
          sort: true
        },
        {
          text: "Табельный номер",
          value: "number",
          sort: true
        },
        {
          text: "№/название группы",
          value: "group",
          renderFunction: value => {
            return `${value.children_group.number}/ ${value.children_group.name}`;
          },
          sort: true
        },
        {
          text: "Опекуны",
          renderFunction: value => {
            return value?.parents_details
              ?.map(el => {
                if (el.additional_fields) {
                  return (
                    phoneFilter.execute(el.phone_number) +
                    ", " +
                    el.additional_fields[0].value +
                    ", " +
                    el.additional_fields[1].value +
                    ", " +
                    " (" +
                    `${
                      dictionariesHelper.accountsStatus.userAuth[
                        el.is_phone_confirmed
                      ]
                    }` +
                    ")"
                  );
                }
              })
              ?.join("\n");
          }
        }
      ]
    };
  },
  computed: {
    sourceObject() {
      return this.$store.getters.getKindergartensChildren;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadKindergartensChildrenList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...this.urlQuery.query,

            children_group__group__organization: this.urlQuery.id,
            kindergartens_pk: this.urlQuery.id

            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchChildrenByKindergartens").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   this.$store.dispatch("clearCardsPage").then(() => next());
  // }
};
</script>

<style scoped></style>
